@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.labelform {
  color: #fdb31f !important;
  margin-bottom: 8px;
}
.text-errors {
  color: #f55b6c !important;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.btn-group.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.home-banner-form .form-group-form .phninpt-sd .flag-dropdown {
  border: unset !important;
  background-color: unset !important;
}
.home-banner-form .form-group-form .phninpt-sd input{
  position: relative;
  left: 35px;
  width: 100%;
}
.signup-area .login-signup-form .form-grp input {
  border-radius: 0px;
}
.signup-area .login-signup-form .form-grp .phninpt-sd  .form-control  {
  position: relative;
  left: 38px;
  padding-bottom: 0px;
  border: none;
  transition: all 0.5s ease-in-out;
}
.signup-area .login-signup-form .form-grp .form-control::placeholder {
  color: #C8C8C8;
}
.signup-area .login-signup-form .form-grp .phninpt-sd  .form-control :focus {
  outline:none
}
.signup-area .login-signup-form .form-grp .phninpt-sd    {
  border-bottom: 1px solid white;
}
.signup-area .login-signup-form .form-grp .react-tel-input .flag-dropdown{
  background-color: unset; 
     border:  unset;
}
/* .signup-area .login-signup-form .form-grp .react-tel-input .flag-dropdown :hover{
  background-color: unset; 
     border:  unset;
} */
/* .signup-area .login-signup-form .form-grp .react-tel-input  .selected-flag .flag , .arrow {
  border: 1px solid #fdb31f !important;
} */
/* .signup-area .login-signup-form .form-grp .react-tel-input .react-tel-input .country-list  :hover{
  background-color: white; 
     border:  unset;
}
.form-control:focus{
  box-shadow: none !important;
}
.signup-area .login-signup-form .form-grp .phninpt-sd  :focus {
  border-bottom: 1px solid #fdb31f !important;
 
} */