#oopss {
  background: linear-gradient(-45deg, #175672, #0f3b49);

  position: fixed;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 1.5em;
  z-index: 9999;
}
#oopss #error-text {
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Shabnam", Tahoma, sans-serif;
  color: #ffffff;
  direction: rtl;
}
#oopss #error-text img {
  /* margin: 85px auto 20px; */
  height: 342px;
}
#oopss #error-text span {
  position: relative;
  font-size: 1.3em;
  font-weight: 900;
  margin-bottom: 20px;
  color: #fdb31f;
}
#oopss #error-text p.p-a {
  font-size: 19px;
  text-align: center;
  margin: 30px 0 15px 0;
}
#oopss #error-text p.p-b {
  font-size: 15px;
}

.back {
  background: #fdb31f;
  color: #000000;
  cursor: pointer;
  /* color: red; */
  font-size: 16px;
  text-decoration: none;
  margin: 2em auto 0;
  padding: 0.7em 2em;
  margin-top: 17px;
  border-radius: 5px;

  font-weight: 900;
}
.back:hover {
  color: #000000;
}

@font-face {
  font-family: Shabnam;
  src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.eot");
  src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.woff") format("woff"),
    url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.woff2") format("woff2"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: Shabnam;
  src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.eot");
  src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.woff") format("woff"),
    url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.woff2") format("woff2"), url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.ttf") format("truetype");
  font-weight: normal;
}
